import Image, { ImageProps } from "next/image"
import styles from "./LoginButton.module.scss"

interface LoginButtonProps {
  icon?: ImageProps["src"]
  text: string
  onClick: () => void
  disabled?: boolean
}

const LoginButton = ({ icon, text, onClick, disabled }: LoginButtonProps) => {
  return (
    <button disabled={disabled} className={styles.loginButton} onClick={onClick}>
      {icon ? (
        <Image
          src={icon}
          alt={`${text} logo`}
          layout="fixed"
          className="tw-object-contain"
          height={32}
          width={120}
        ></Image>
      ) : (
        <div style={{ width: 32 }} />
      )}
      <span className={styles.buttonText}>{text}</span>
    </button>
  )
}

export default LoginButton
