import {
  GoogleAuthProvider,
  OAuthProvider,
  createUserWithEmailAndPassword,
  getAdditionalUserInfo,
  getAuth,
  sendEmailVerification,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth"
import { getHost } from "hooks/misc/useHost"

export const startLoginWithEmail = async (email: string, password: string) => {
  await signInWithEmailAndPassword(getAuth(), email, password)
}

export const startLoginWithGoogle = async () => {
  getAuth().languageCode = "de"
  try {
    const googleAuthProvider = new GoogleAuthProvider()
    await signInWithPopup(getAuth(), googleAuthProvider)
  } catch (error) {
    console.error(error)
  }
}

export const startLoginWithMicrosoft = async () => {
  getAuth().languageCode = "de"
  try {
    const microsoftAuthProvider = new OAuthProvider("microsoft.com")
    await signInWithPopup(getAuth(), microsoftAuthProvider)
  } catch (error) {
    console.error(error)
  }
}

export const startLoginWithApple = async () => {
  getAuth().languageCode = "de"
  try {
    const provider = new OAuthProvider("apple.com")
    provider.addScope("email")
    provider.addScope("name")
    provider.setCustomParameters({
      locale: "de",
    })
    const result = await signInWithPopup(getAuth(), provider)
    console.log("Apple result", result)

    const additionalUserInfo = getAdditionalUserInfo(result)
    console.log("Apple additional", additionalUserInfo)
  } catch (error) {
    console.error(error)
  }
}

export const startSignUpWithEmail = async (
  newUser: { email: string; password: string },
  shopId: string,
  options: { skipEmailVerification?: boolean } = { skipEmailVerification: false }
) => {
  try {
    getAuth().languageCode = "de"

    const result = await createUserWithEmailAndPassword(getAuth(), newUser.email, newUser.password)
    const user = result.user

    const { origin } = getHost()

    const actionCodeSettings = {
      url: `${origin}/${shopId}`,
    }

    if (!options.skipEmailVerification) {
      await sendEmailVerification(user, actionCodeSettings)
    }

    //Check if userdoc got created
  } catch (error) {
    console.error(error)
  }
}
